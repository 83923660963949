const isDev = process.env.NODE_ENV === 'development';
const useGlobalDomains = false;

const IP_ADDRESS = true ? 'localhost' : '192.168.0.197';
export const WORKERS = (() => ({
  JOB_TEMPLATES_WORKER: isDev
    ? `http://${IP_ADDRESS}:8787/`
    : 'https://worker-jobtemplates.greetle.workers.dev',
  INTEGRATIONS_WORKER: isDev
    ? `http://${IP_ADDRESS}:8788/`
    : 'https://worker-integrations.greetle.workers.dev',
  APPLICATIONS_WORKER: isDev
    ? `http://${IP_ADDRESS}:8786/`
    : 'https://worker-applications.greetle.workers.dev',
  USERS_WORKER: isDev
    ? `http://${IP_ADDRESS}:8785/`
    : 'https://worker-users.greetle.workers.dev',
  ROOMS_WORKER: isDev
    ? `http://${IP_ADDRESS}:8782/`
    : 'https://worker-rooms.greetle.workers.dev',
  MAILER_WORKER: isDev
    ? `http://${IP_ADDRESS}:8781/`
    : 'https://worker-mailer.greetle.workers.dev',
}))();

export const highServiceURL = (() => {
  return !isDev
    ? 'https://api.high.greetai.co'
    : useGlobalDomains
    ? 'https://local-high.greetai.co'
    : `http://${IP_ADDRESS}:5300`;
})();

export const mediaServerSocketURL = !isDev
  ? 'wss://mediaserver.greetai.co'
  : useGlobalDomains
  ? 'https://local-mediaserver.greetai.co'
  : `ws://localhost:9040`;

export const messagingServerURL = (() => {
  return !isDev ? 'https://messaging.greetai.co' : `http://${IP_ADDRESS}:9090`;
})();

export const recorderServiceUrl = !isDev
  ? 'https://recorder-6lertodt4a-ew.a.run.app'
  : `http://localhost:5400`;
